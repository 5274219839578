// extracted by mini-css-extract-plugin
export var content = "FeaturedContent-module--content--d42a1";
export var eyebrow = "FeaturedContent-module--eyebrow--e23ec";
export var headingH2 = "FeaturedContent-module--headingH2--5a586";
export var headingH3 = "FeaturedContent-module--headingH3--79b64";
export var headingH4 = "FeaturedContent-module--headingH4--6faad";
export var headingH5 = "FeaturedContent-module--headingH5--55e7c";
export var headingH6 = "FeaturedContent-module--headingH6--47bec";
export var imageContainer = "FeaturedContent-module--imageContainer--c2afc";
export var link = "FeaturedContent-module--link--95242";
export var opacityEnter = "FeaturedContent-module--opacityEnter--4c90d";
export var opacityExit = "FeaturedContent-module--opacityExit--22584";
export var paragraph = "FeaturedContent-module--paragraph--6984a";
export var rollDown = "FeaturedContent-module--rollDown--765d1";
export var rollUp = "FeaturedContent-module--rollUp--cb2f9";
export var root = "FeaturedContent-module--root--48814";
export var slideInDown = "FeaturedContent-module--slideInDown--8fb98";
export var slideOutUp = "FeaturedContent-module--slideOutUp--d33b7";
export var splashEnter = "FeaturedContent-module--splashEnter--c9a0e";
export var splashExit = "FeaturedContent-module--splashExit--aeef5";
export var textContainer = "FeaturedContent-module--textContainer--9170f";
export var wrapper = "FeaturedContent-module--wrapper--f2b16";
import React from 'react';
import ImageWithWhiteBox from '@/components/modules/ImageWithWhiteBox';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import SectionRichText from '../SectionRichText';
import type { FeaturedContentTypes } from './FeaturedContent.types';
import * as styles from './FeaturedContent.module.scss';

export default function FeaturedContent({
  eyebrow,
  body,
  card_eyebrow,
  card_body,
  image,
  background,
  top_spacing = 'md',
  bottom_spacing = 'md',
}: FeaturedContentTypes) {
  return (
    <section className={styles.wrapper} data-background={String(background)}>
      <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing}>
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <p className={styles.eyebrow}>{eyebrow}</p>
            <SectionRichText body={body} className={styles} />
          </div>
          <ImageWithWhiteBox className={styles.imageContainer} image={image} body={card_body} eyebrow={card_eyebrow} />
        </div>
      </ModuleWrapper>
    </section>
  );
}

import React from 'react';
import Image from '@/components/elements/Image';
import { ImageWithWhiteBoxProps } from './ImageWithWhiteBox.types';
import * as styles from './ImageWithWhiteBox.module.scss';

const ImageWithWhiteBox = ({ image, body, eyebrow, className }: ImageWithWhiteBoxProps) => {
  return (
    <div className={`${className} ${styles.wrapper}`}>
      {image && <Image className={styles.image} data={image} fluid />}
      {eyebrow && body && (
        <div className={styles.box}>
          <h4 className={styles.title}>{eyebrow}</h4>
          <p className={styles.body}>{body}</p>
        </div>
      )}
    </div>
  );
};

export default ImageWithWhiteBox;

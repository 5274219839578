// extracted by mini-css-extract-plugin
export var body = "ImageWithWhiteBox-module--body--9dd02";
export var box = "ImageWithWhiteBox-module--box--63ef9";
export var image = "ImageWithWhiteBox-module--image--1cf91";
export var opacityEnter = "ImageWithWhiteBox-module--opacityEnter--1f3a6";
export var opacityExit = "ImageWithWhiteBox-module--opacityExit--75c72";
export var rollDown = "ImageWithWhiteBox-module--rollDown--47c3a";
export var rollUp = "ImageWithWhiteBox-module--rollUp--38e12";
export var slideInDown = "ImageWithWhiteBox-module--slideInDown--4ab38";
export var slideOutUp = "ImageWithWhiteBox-module--slideOutUp--103f6";
export var splashEnter = "ImageWithWhiteBox-module--splashEnter--eea17";
export var splashExit = "ImageWithWhiteBox-module--splashExit--bd4c0";
export var title = "ImageWithWhiteBox-module--title--679c3";
export var wrapper = "ImageWithWhiteBox-module--wrapper--3aaea";